'use client';

import useEmblaCarousel from 'embla-carousel-react';

import TestimonialCard from '@/app/_components/testimonial-card';
import { DisplayTestimonial } from '@/domain/testimonial/services/types';

import CarouselControls from './controls';

interface TestimonialCarouselProps {
  testimonials: Array<
    DisplayTestimonial & {
      investedIn: React.ReactElement;
      mobileComment?: string;
    }
  >;
  children: React.ReactNode;
}

const TestimonialCarousel = ({
  testimonials,
  children,
}: TestimonialCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: true,
    watchResize: true,
    axis: 'x',
  });

  return (
    <div className="flex flex-col items-center gap-0 overflow-hidden xl:flex-row xl:items-stretch xl:gap-12">
      <div className="z-20 bg-gradient-to-r from-primary-50 from-80% to-transparent md:flex md:flex-col">
        {children}

        <div className="mt-10 hidden xl:block">
          <CarouselControls emblaApi={emblaApi} />
        </div>
      </div>

      <div className="z-10 my-10 w-full xl:my-0">
        <div className="overflow-hidden" ref={emblaRef}>
          <div className="flex touch-pan-y backface-hidden *:mx-5">
            {testimonials.map(
              ({
                id,
                profilePicture,
                fullName,
                investedIn,
                comment,
                mobileComment,
                stars,
              }) => (
                <div
                  className="relative flex min-w-full lg:w-[calc(100%/3)] lg:min-w-[calc(100%/3)]"
                  key={id}
                >
                  <TestimonialCard
                    profilePictureUrl={profilePicture}
                    fullname={fullName}
                    investedIn={investedIn}
                    content={comment}
                    mobileContent={mobileComment}
                    rating={stars}
                  />
                </div>
              ),
            )}
          </div>
        </div>

        <div className="-mb-10 mt-14 flex justify-center md:-mb-5 md:mt-20 xl:hidden">
          <CarouselControls emblaApi={emblaApi} />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
