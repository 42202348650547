'use client';

import { EmblaCarouselType } from 'embla-carousel';

import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

import { Button } from '@/app/_components/ui/button';
import { Icons } from '@/app/_components/ui/icons';

interface CarouselControlsProps {
  emblaApi: EmblaCarouselType | undefined;
}

const CarouselControls = ({ emblaApi }: CarouselControlsProps) => {
  const t = useTranslations();
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="flex flex-row gap-x-3">
      <Button
        className="size-12 p-0"
        cornerStyle="pillLeft"
        aria-label={t('common.previous')}
        onClick={onPrevButtonClick}
      >
        <Icons.StraightLeftArrow className="size-6" />
      </Button>

      <Button
        className="size-12 p-0"
        cornerStyle="pillRight"
        aria-label={t('common.next')}
        onClick={onNextButtonClick}
      >
        <Icons.RightArrow className="size-6" />
      </Button>
    </div>
  );
};

export default CarouselControls;
