import { Icons } from '@/app/_components/ui/icons';

export interface RatingStarsProps {
  value: number;
  starClassName?: string;
}

const RatingStars = ({ value, starClassName }: RatingStarsProps) => {
  const truncatedValue = Math.min(5, Math.max(0, Math.round(value))); // truncated value is now between 0 and 5
  return (
    <div className="flex gap-1">
      {Array.from(Array(5)).map((_, index) =>
        index + 1 <= truncatedValue ? (
          // eslint-disable-next-line react/no-array-index-key
          <Icons.StarFilled key={index} className={starClassName} />
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <Icons.Star key={index} className={starClassName} />
        ),
      )}
    </div>
  );
};

export default RatingStars;
