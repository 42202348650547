'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from '@/app/_components/ui/button';

import { useLocale } from '@/lib/translation/hooks';

import { PressArticleDocument } from '../../../prismicio-types';

import PressArticle from './_components/press-article';
import getMoreArticles from './action';

interface ClientProps {
  defaultArticles: Array<PressArticleDocument>;
  pageSize: number;
  totalCount: number;
}

const Client = ({ defaultArticles, pageSize, totalCount }: ClientProps) => {
  const t = useTranslations();
  const locale = useLocale();
  const [articles, setArticles] = useState(defaultArticles);

  const handleSeeMore = async () => {
    const newArticles = await getMoreArticles(
      articles.length,
      pageSize,
      locale,
    );

    setArticles([...articles, ...newArticles]);
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:grid-cols-3 3xl:grid-cols-4">
        {articles.map((article) => (
          <PressArticle key={article.uid} article={article.data} />
        ))}
      </div>
      {articles.length < totalCount ? (
        <div>
          <Button onClick={handleSeeMore}>{t('common.see-more')}</Button>
        </div>
      ) : null}
    </>
  );
};

export default Client;
