import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
import tailwindAnimate from 'tailwindcss-animate';

// Utility to handle the animation delay and the transition regarding the infinite scrolling banner
const animationDelayAndTransition = plugin(
  ({ matchUtilities, addUtilities }) => {
    matchUtilities({
      'animation-delay': (value) => ({
        'animation-delay': `calc(${value}*-1)`,
      }),
      'animation-delay-halved': (value) => ({
        'animation-delay': `calc(${value}/-2)`,
      }),
      'animation-duration-carousel': (value) => ({
        'animation-duration': `${value}`,
      }),
    });
    addUtilities({
      '.transition-5': { transition: '0.5s' },
    });
  },
);

const itemWidthRepartitionUtilities = plugin(({ matchUtilities, theme }) => {
  matchUtilities(
    {
      'w-item-2': (value) => ({
        width: `calc((100% / 2) - 2*${value})`,
      }),
      'w-item-3': (value) => ({
        width: `calc((100% / 3) - 2*${value})`,
      }),
    },
    {
      values: theme('gap'),
      supportsNegativeValues: false,
    },
  );
});

const liCustomMarker = plugin(({ addUtilities }) => {
  addUtilities({
    '.custom-marker::marker': { color: 'hsl(var(--primary))' },
  });
});

// Utility types taken from https://github.com/sambauers/tailwindcss-3d
const tailwind3dUtilities = plugin(
  ({ addUtilities, matchUtilities, theme }) => {
    addUtilities({
      '.backface-visible': {
        'backface-visibility': 'visible',
      },
      '.backface-hidden': {
        'backface-visibility': 'hidden',
      },
      '.transform-style-flat': {
        'transform-style': 'flat',
      },
      '.transform-style-3d': {
        'transform-style': 'preserve-3d',
      },
      '.perspective-1000': {
        perspective: '1000px',
      },
    });
    matchUtilities(
      {
        'rotate-y': (value) => ({
          '--tw-rotate-y': value,
          transform:
            'translate(var(--tw-translate-x), var(--tw-translate-y)) rotateX(var(--tw-rotate)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))',
        }),
      },
      {
        values: theme('rotate'),
        supportsNegativeValues: true,
      },
    );
  },
);

const config: Config = {
  darkMode: ['class'],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
  ],
  theme: {
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      screens: {
        '3xl': '1700px',
      },
      colors: {
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        'alt-background': 'hsl(var(--alt-background))',
        foreground: 'hsl(var(--foreground))',
        primary: {
          50: 'hsl(var(--primary-50))',
          100: 'hsl(var(--primary-100))',
          400: 'hsl(var(--primary-400))',
          DEFAULT: 'hsl(var(--primary))',
          foreground: 'hsl(var(--primary-foreground))',
          muted: 'hsl(var(--muted-primary))',
        },
        secondary: {
          DEFAULT: 'hsl(var(--secondary))',
          foreground: 'hsl(var(--secondary-foreground))',
        },
        success: {
          DEFAULT: 'hsl(var(--success))',
          foreground: 'hsl(var(--success-foreground))',
          muted: 'hsl(var(--muted-success))',
        },
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))',
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))',
        },
        accent: {
          DEFAULT: 'hsl(var(--accent))',
          foreground: 'hsl(var(--accent-foreground))',
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))',
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))',
        },
        lin: {
          DEFAULT: 'hsl(var(--lin))',
        },
        complementary: {
          DEFAULT: 'hsl(var(--complementary))',
          alt: 'hsl(var(--alt-muted-complementary))',
          muted: 'hsl(var(--muted-complementary))',
        },
        golden: {
          DEFAULT: 'hsl(var(--golden))',
          alt: 'hsl(var(--alt-golden))',
          muted: 'hsl(var(--muted-golden))',
        },
      },
      borderRadius: {
        xs: 'calc(var(--radius) * 0.125) /* 1px */',
        s: 'calc(var(--radius) * 0.2) /* 1.6px */',
        sm: 'calc(var(--radius) * 0.25) /* 2px */',
        DEFAULT: 'calc(var(--radius) * 0.5) /* 4px */',
        md: 'calc(var(--radius) * 0.75) /* 6px */',
        lg: 'var(--radius) /* 8px */',
        xl: 'calc(var(--radius) * 1.5) /* 12px */',
        '2xl': 'calc(var(--radius) * 2) /* 16px */',
        '3xl': 'calc(var(--radius) * 3) /* 24px */',
        '4xl': 'calc(var(--radius) * 4) /* 32px */',
        '5xl': 'calc(var(--radius) * 5) /* 40px */',
      },
      spacing: {
        18: '4.5rem /* 72px */',
        22: '5.5rem /* 88px */',
        88: '22rem /* 352px */',
        112: '30rem /* 480px */',
        128: '36rem /* 576px */',
      },
      height: {
        typeform: '31.25rem',
      },
      flex: {
        '40': '0 0 40%',
        '100': '0 0 100%',
      },
      maxWidth: ({ theme }) => theme('width'),
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        'infinite-scroll-up': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(-100%)' },
        },
        'infinite-scroll-down': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(100%)' },
        },
        'infinite-scroll-left': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%)' },
        },
        'infinite-scroll-right': {
          from: { transform: 'translateX(0%)' },
          to: { transform: 'translateX(100%)' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'infinite-scroll-up': 'infinite-scroll-up 10s linear infinite',
        'infinite-scroll-down': 'infinite-scroll-down 10s linear infinite',
        'infinite-scroll-left': 'infinite-scroll-left 20s linear infinite',
        'infinite-scroll-right': 'infinite-scroll-right 20s linear infinite',
      },
      fontFamily: {
        sans: ['var(--font-manrope)'],
        heading: ['var(--font-poppins)'],
        brand: ['var(--font-inter)'],
      },
    },
  },
  plugins: [
    tailwindAnimate,
    tailwind3dUtilities,
    animationDelayAndTransition,
    itemWidthRepartitionUtilities,
    liCustomMarker,
  ],
};

export default config;
