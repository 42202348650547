'use client';

import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  PrismicRichText,
  SliceComponentProps,
} from '@prismicio/react';
import { Widget } from '@typeform/embed-react';
import { useTranslations } from 'next-intl';

import { Waypoint } from 'react-waypoint';

import Heading from '@/app/_components/heading';
import analyticsManager from '@/lib/analytics';
import { cn } from '@/lib/utils';

const headingComponents: JSXMapSerializer = {
  heading2: ({ children }) => (
    <Heading as="h2" className="text-center" size="md">
      {children}
    </Heading>
  ),
  // @adr /prismic/ACTIVE-20240213-format-richtext.md
  strong: ({ children }) => <span className="text-primary">{children}</span>,
};

/**
 * Props for `Typeform`.
 */
export type TypeformProps = SliceComponentProps<Content.TypeformSlice>;

/**
 * Component for "Typeform" Slices.
 */
const Typeform = ({ slice }: TypeformProps): JSX.Element => {
  const t = useTranslations();
  const formId = slice.primary.formId?.toString();
  const anchorId = slice.primary.anchorid?.toString();

  return (
    <Waypoint
      onEnter={() =>
        document
          .getElementById('typeFormButton')
          ?.setAttribute('hidden', 'true')
      }
      onLeave={() =>
        document.getElementById('typeFormButton')?.removeAttribute('hidden')
      }
    >
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className={cn(
          'flex flex-col gap-10 px-6',
          slice.primary.remove_top_spacing ? 'pt-0' : 'pt-16',
          slice.primary.remove_bottom_spacing ? 'pb-0' : 'pb-16',
        )}
        id={anchorId}
      >
        <PrismicRichText
          components={headingComponents}
          field={slice.primary.title}
        />
        <div className="flex h-typeform w-full flex-col items-center justify-center rounded-2xl bg-primary-50 p-6">
          {formId ? (
            <Widget
              id={formId}
              className="size-full"
              onSubmit={() => {
                analyticsManager.client.sendEvent({
                  event: 'form_submit',
                  form_id: formId,
                });
              }}
            />
          ) : (
            <p>{t('pages.landing.typeForm.missingId')}</p>
          )}
        </div>
      </section>
    </Waypoint>
  );
};

export default Typeform;
