'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import Link from '@/lib/translation/components/link';

/**
 * Props for `Summary`.
 */
export type SummaryProps = SliceComponentProps<Content.SummarySlice>;

/**
 * Component for "Summary" Slices.
 */
const Summary = ({ slice }: SummaryProps) => {
  const [titles, setTitles] = useState<HTMLHeadingElement[]>([]);
  const t = useTranslations();

  useEffect(() => {
    if (document) {
      setTitles(
        Array.from(document.querySelectorAll('h2')).filter((elem) =>
          elem.checkVisibility(),
        ),
      );
    }
  }, []);

  if (titles.length === 0) {
    return null;
  }

  return (
    <div className="px-6 md:py-6">
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex w-full flex-col gap-4 rounded-lg border border-primary-100 bg-primary-50 p-4"
      >
        <p className="text-2xl font-bold text-primary">
          {t('pages.guides.summary.title')}
        </p>
        <ul className="flex flex-col gap-2">
          {titles.map((title) => (
            <li key={title.innerText}>
              <Link className="text-sm hover:underline" href={`#${title.id}`}>
                {title.innerText}
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Summary;
