'use client';

import Image from 'next/image';

import { spacing } from 'tailwindcss/defaultTheme';

import RatingStars from '@/app/_components/ui/rating-stars';

export interface TestimonialCardProps {
  profilePictureUrl: string | null;
  fullname: string;
  investedIn: React.ReactElement;
  content: string;
  mobileContent?: string;
  rating: number;
}

const TestimonialCard = ({
  profilePictureUrl,
  fullname,
  investedIn,
  content,
  mobileContent,
  rating,
}: TestimonialCardProps) => {
  return (
    <div className="relative flex w-full max-w-screen flex-col items-center gap-4 rounded-xl rounded-tr-sm bg-white px-8 py-12 lg:max-w-[420px]">
      <Image
        className="absolute left-3 top-3 -rotate-90 md:left-6 md:top-6"
        width={26}
        height={26}
        role="presentation"
        alt=""
        src="/corner.png"
      />

      <Image
        className="absolute bottom-3 right-3 rotate-90 md:bottom-6 md:right-6"
        width={26}
        height={26}
        role="presentation"
        alt=""
        src="/corner.png"
      />

      {profilePictureUrl ? (
        <div className="relative block size-32">
          <Image
            fill
            sizes={spacing['64']}
            className="rounded-full object-cover"
            src={profilePictureUrl}
            alt={fullname}
          />
        </div>
      ) : null}

      <p className="text-sm font-bold">{fullname}</p>

      <RatingStars value={rating} starClassName="text-secondary" />

      {investedIn}

      <p className="hidden text-justify text-sm md:block">{content}</p>
      <p className="text-justify text-sm md:hidden">
        {mobileContent !== '' ? mobileContent : content}
      </p>
    </div>
  );
};

export default TestimonialCard;
