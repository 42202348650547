import { RichTextField } from '@prismicio/client';
import {
  JSXFunctionSerializer,
  JSXMapSerializer,
  PrismicRichText,
} from '@prismicio/react';

import { cn } from '@/lib/utils';

interface DeviceRichTextProps {
  richText: RichTextField;
  mobileRichText: RichTextField;
  components?: JSXMapSerializer | JSXFunctionSerializer | undefined;
  mobileComponents?: JSXMapSerializer | JSXFunctionSerializer | undefined;
  className?: string;
}

const DeviceRichText = ({
  richText,
  mobileRichText,
  components,
  mobileComponents,
  className,
}: DeviceRichTextProps): JSX.Element => {
  return (
    <>
      <div className={cn('w-full', className, 'hidden md:contents')}>
        <PrismicRichText components={components} field={richText} />
      </div>
      <div className={cn('w-full', className, 'md:hidden')}>
        <PrismicRichText
          components={mobileComponents ?? components}
          field={mobileRichText.length !== 0 ? mobileRichText : richText}
        />
      </div>
    </>
  );
};

export default DeviceRichText;
