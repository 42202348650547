'use client';

import { ColorField, Content } from '@prismicio/client';

import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';

import { EmblaCarouselType } from 'embla-carousel';
import Autoplay, { AutoplayType } from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

import { cn } from '@/lib/utils';

import SliceCta from '@/slices/_shared/cta';
import NewsTag from '@/slices/NewsCarousel/_components/news-tag';

interface DesktopNewsCarouselProps {
  speed: number;
  items: Content.NewsCarouselSliceDefaultItem[];
  components: JSXMapSerializer;
  backgroundColor: ColorField;
}

const DesktopNewsCarousel = ({
  speed,
  items,
  components,
  backgroundColor,
}: DesktopNewsCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { axis: 'y', align: 'start', loop: true },
    [
      Autoplay({
        delay: speed,
        stopOnInteraction: false,
        stopOnMouseEnter: false, // handled manually to work on the illustration too
      }),
      ClassNames(),
    ],
  );

  const [selectedSlide, setSelectedSlide] = useState(items[0]);
  const updateSelectedSlide = useCallback(
    (api: EmblaCarouselType) => {
      setSelectedSlide(items[api.selectedScrollSnap()]);
    },
    [items],
  );

  const selectSlide = useCallback(
    (index: number) => {
      if (!emblaApi) {
        return;
      }

      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const pauseScroll = useCallback(() => {
    const autoplay: AutoplayType = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    autoplay.stop();
  }, [emblaApi]);

  const resumeScroll = useCallback(() => {
    const autoplay: AutoplayType = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    autoplay.play();
  }, [emblaApi]);

  // Synchronize scroll and selected slide (allow us to update the image)
  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    updateSelectedSlide(emblaApi);
    emblaApi.on('select', updateSelectedSlide);
    emblaApi.on('reInit', updateSelectedSlide);
  }, [emblaApi, updateSelectedSlide]);

  return (
    <div
      className="relative flex w-full flex-row items-center"
      onMouseEnter={pauseScroll}
      onMouseLeave={resumeScroll}
    >
      <div
        className="absolute -bottom-6 -left-6 flex h-1/2 w-[calc(100%+48px)] items-center justify-center self-center rounded-2xl rounded-tr-md p-8 text-white shadow-lg"
        style={{
          backgroundColor: backgroundColor
            ? `${backgroundColor.toString()}`
            : 'hsl(var(--primary))',
        }}
      />
      <div className="z-20 w-1/2 rounded-2xl bg-white p-6 shadow-[0_0_30px_-15px_hsl(var(--muted-foreground))]">
        <div
          className={cn(
            'relative overflow-hidden',
            'after:pointer-events-none after:absolute after:inset-x-0 after:bottom-0 after:h-16 after:bg-gradient-to-t after:from-white',
          )}
          ref={emblaRef}
        >
          <div className="flex h-112 touch-pan-x flex-col gap-y-2 backface-hidden">
            {items.map(({ title, summary, label_name, label_color }, index) => (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                // Using a drop-shadow to highlight the selected item added 2px
                // to the item size and therefore messed up with Embla calculations.
                // We used an inner-shadow instead to prevent modifying the item size.
                // `[&.is-snapped]` targets any element that has a `.is-snapped` class
                // applied to it and and `shadow-[inset_0_0_2px_1px_hsl(var(--primary))]`
                // adds the inner shadow to the element.
                className="flex grow flex-col gap-y-2 rounded p-5 text-left last:mb-2 hover:shadow-[inset_0_0_1px_1px_hsl(var(--primary))] [&.is-snapped]:bg-primary-50 [&.is-snapped]:shadow-[inset_0_0_2px_1px_hsl(var(--primary))]"
                onClick={() => selectSlide(index)}
                type="button"
              >
                <div className="flex h-40 flex-col justify-around xl:h-32">
                  {label_name ? (
                    <NewsTag color={label_color} label={label_name} />
                  ) : null}
                  {/* Here we don't need to modify the Prismic riche text because we know for a fact we are in desktop */}
                  <PrismicRichText components={components} field={title} />
                  <PrismicRichText components={components} field={summary} />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div
        className="relative h-112 w-1/2 rounded-r-2xl bg-cover bg-center shadow-[0_0_30px_-15px_hsl(var(--muted-foreground))] transition-all duration-1000"
        style={{
          backgroundImage: `url(${selectedSlide.illustration.url})`,
        }}
      >
        <SliceCta
          field={selectedSlide.button}
          className="absolute inset-x-4 bottom-4 xl:left-auto"
        />
      </div>
    </div>
  );
};

export default DesktopNewsCarousel;
