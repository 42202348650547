import { CSSProperties, PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

interface SliceContainerProps extends PropsWithChildren {
  slice: {
    slice_type: string;
    variation: string;
  };
  className?: string;
  style?: CSSProperties;
}

const SliceContainer = ({
  slice,
  children,
  className,
  style,
}: SliceContainerProps) => {
  return (
    <section
      className={cn(
        'flex flex-col items-center gap-8 px-6 py-16 sm:gap-16',
        className,
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      style={style}
    >
      {children}
    </section>
  );
};

export default SliceContainer;
