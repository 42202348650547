import { ColorField, KeyTextField } from '@prismicio/client';

interface NewsTagProps {
  color: ColorField;
  label: KeyTextField;
}

const NewsTag = ({ color, label }: NewsTagProps) => {
  return (
    <p
      className="w-fit rounded-full px-3 py-1 text-xs"
      style={{
        // `#xxxxxx1A` = Hex color with 10% opacity
        backgroundColor: color
          ? `${color.toString()}1A`
          : 'hsla(var(--primary), 0.1)',
        color: color ? color.toString() : 'hsl(var(--primary))',
      }}
    >
      {label}
    </p>
  );
};

export default NewsTag;
